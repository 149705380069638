import React, { useState } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const [active, setActive] = useState(true);
  const project = [
    {
      title: "Sattva Exotica",
      image: "pic4.jpg",
    },
    {
      title: "Shubh Greens",
      image: "pic2.jpg",
    },
    {
      title: "Tata Projects – Anant University",
      image: "pic3.jpg",
    },
    {
      title: "Sanskardham",
      image: "pic4.jpg",
    },
    {
      title: "Ozone Tuscany",
      image: "pic5.jpg",
    },
    {
      title: "Iscon LIG Housing",
      image: "pic6.jpg",
    },
    {
      title: "SPG Evans",
      image: "pic8.jpg",
    },
    {
      title: "Sepal Elegant",
      image: "pic9.jpg",
    },
    {
      title: "Sattva Exotica",
      image: "pic2.jpg",
    },
    {
      title: "Hirabaug",
      image: "pic3.jpg",
    },
    {
      title: "Private Bungalow at Khoraj",
      image: "pic4.jpg",
    },
  ];

  const toggle = () => {
    setActive(!active);
  };
  return (
    <div className="page-wraper">
      <div className="page-content">
        <div className="landingpage">
          <ul>
            <li>
              <h2 className="text-white">We Consult</h2>
              <p>
                With a core team of highly qualified consultants, we consult
                investors into making the right real estate investments with
                high integrity guaranteeing optimized returns.
              </p>
            </li>
          </ul>
        </div>
        {/* meet & ask */}
        <div className="section-full z-index100 meet-ask-outer">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 meet-ask-row p-tb30">
                <div className="row d-flex">
                  <div className="col-lg-6">
                    <div className="icon-bx-wraper clearfix left">
                      <div className="icon-xl ">
                        {" "}
                        <span className=" icon-cell text-white">
                          <i className="flaticon-buildings" />
                        </span>{" "}
                      </div>
                      <div className="icon-content text-white">
                        <h3 className="dez-tilte text-uppercase m-b10">
                          Meet &amp; Ask
                        </h3>
                        <p>
                          Constructing a modern website requires modern tools.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 m-t20">
                    <Link
                      to="/contact"
                      className="site-button-secondry button-skew m-l10"
                    >
                      <span>Contact Us</span>
                      <i className="fa fa-angle-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content-wrapper">
          <div class="background-overlay"></div>
          <div class="content-box">
            <p class="headline">
              We, at Aranath Group, strive to help our investors make informed
              and high ROI investments in these sectors
            </p>
            <p class="sectors">
              <span>
                <i class="fa-solid fa-building"></i> COMMERCIAL
              </span>{" "}
              |
              <span>
                <i class="fa fa-home"></i> RESIDENTIAL
              </span>{" "}
              |
              <span>
                <i class="fa-solid fa-award"></i> PRE-LEASED
              </span>{" "}
              |
              <span>
                <i class="fa fa-cubes"></i> LANDS
              </span>{" "}
              |
              <span>
                <i class="fa-solid fa-bullhorn"></i> REAL ESTATE ADVISORY
              </span>
            </p>
          </div>
        </div>

        <div className="section-full content-inner ">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="text-uppercase">Our Principles</h2>
              <p>
                At Aranath Group, our investment philosophy is guided by two
                main principles:
              </p>
            </div>
            <div className="row section-content">
              <div className="col-md-6 col-12 item">
                <blockquote>
                  <h4>Identification of Best-Suited Growth Pockets:</h4>
                  <p>
                    We believe in tailoring investment strategies to meet the
                    unique interests, horizon, and asset preferences of each
                    investor. Our team of experts conducts thorough market
                    research and analysis to identify the most promising growth
                    pockets, ensuring optimal returns and risk management for
                    our clients.
                  </p>
                </blockquote>
              </div>
              <div className="col-md-6 col-12 item">
                <blockquote>
                  <h4>Partnerships with Grade A Developers:</h4>
                  <p>
                    We are committed to working exclusively with Grade A
                    developers who have a proven track record of goodwill and
                    trust in the market. By partnering with reputable
                    developers, we ensure the integrity and quality of the
                    projects we invest in, providing our clients with peace of
                    mind and confidence in their investment decisions.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
        <button
          className="scroltop fa fa-arrow-up style5"
          style={{ background: "#9a2331", color: "#fff" }}
        />
      </div>
    </div>
  );
};

export default Home;
