import React, { useState } from "react";
import { Link } from "react-router-dom";

const Career = () => {
  const [searchText, setSearchText] = useState("");

  const handleMobileMenu = () => {
    var mobileMenu = document.getElementById("navbarNavDropdown");
    mobileMenu.classList.toggle("show");
  };
  return (
    <div>
      <div id="loading-area" />
      <div className="page-wraper">
        <div className="page-content bg-white">
          {/* inner page banner */}
          <div
            className="dez-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(images/background/bg4.jpg)" }}
          >
            <div className="container px-5 text-start">
              <div className="dez-bnr-inr-entry">
                <h1 className="text-white">Career</h1>
              </div>
            </div>
          </div>
          {/* inner page banner END */}
          {/* Breadcrumb row */}
          <div className="breadcrumb-row">
            <div className="container px-5 text-start">
              <ul className="list-inline">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li> Career</li>
              </ul>
            </div>
          </div>
          {/* Breadcrumb row END */}
          {/* contact area */}
          <div className="content-area">
            {/* Career */}
            <div className="container px-5">
              <div className="row">
                {/* Left part start */}
                <div className="col-lg-12 m-b30 text-start">
                  <h1 className="m-b20 m-t0">Openings </h1>
                  <div
                    className="dez-separator"
                    style={{ backgroundColor: "#9a2331" }}
                  />
                  <div className="row">
                    <div className="col-md-5 col-sm-12 p-2">
                      <h2 className="m-t30 m-b10 ">Project management intern</h2>
                      <ul className="list-angle-right">
                        <li>
                          <strong>Work Status:</strong> Full time
                        </li>
                        <li>
                          <strong>Qualifications:</strong> BE/B.Tech/MCA/BCA
                        </li>
                        <li>
                          <strong>Experience:</strong> 1-3 Years
                        </li>
                        <li>
                          <strong>vacancy:</strong> 1
                        </li>
                        <li>
                          <strong>Location:</strong> Ahmedabad
                        </li>
                      </ul>
                      <p>
                      A project management intern typically assists project managers in planning, executing, and evaluating various projects within an organization.Assist project managers in developing project plans, including goals, timelines, and budgets.
                      </p>
                      <h3>Our Offerings</h3>
                      <ul className="list-check-circle primary">
                        <li>Competitive salary with performance bonuses</li>
                        <li>Health, dental, and vision insurance</li>
                        <li>Retirement plan options</li>
                        <li>
                          Professional development and training opportunities
                        </li>
                        <li>A vibrant and inclusive workplace culture</li>
                      </ul>
                      <Link to="/contact" className="site-button button-skew">
                        <span>Apply Now</span>
                        <i className="fa fa-angle-right" />
                      </Link>
                    </div>
                    <div className="col-md-2 col-sm-0"></div>
                    <div className="col-md-5 col-sm-12 p-2">
                      <h2 className="m-t30 m-b10 ">Civil Engineer</h2>
                      <ul className="list-angle-right">
                        <li>
                          <strong>Work Status:</strong> Full time
                        </li>
                        <li>
                          <strong>Qualifications:</strong> BE/B.Tech/MCA/BCA
                        </li>
                        <li>
                          <strong>Experience:</strong> 1-3 Years
                        </li>
                        <li>
                          <strong>vacancy:</strong> 1
                        </li>
                        <li>
                          <strong>Location:</strong> Ahmedabad
                        </li>
                      </ul>
                      <p>
                      We are looking for a new civil engineer who is not only adept at planning, designing, and overseeing the construction of buildings and infrastructure but also brings a fresh perspective to our projects. we would love to hear from you.
                      </p>
                      <h3>Our Offerings</h3>
                      <ul className="list-check-circle primary">
                        <li>Competitive salary with performance bonuses</li>
                        <li>Health, dental, and vision insurance</li>
                        <li>Retirement plan options</li>
                        <li>
                          Professional development and training opportunities
                        </li>
                        <li>A vibrant and inclusive workplace culture</li>
                      </ul>
                      <Link to="/contact" className="site-button button-skew">
                        <span>Apply Now</span>
                        <i className="fa fa-angle-right" />
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="row">
                    
                  </div>
                </div>
                {/* Left part start END */}
              </div>
            </div>
            {/* Career END */}
          </div>
          {/* contact area END */}
        </div>
        {/* Content END*/}
        {/* Footer */}
      </div>
    </div>
  );
};

export default Career;
