import React, { useState } from "react";
import { Link } from "react-router-dom";

const Projects = () => {
  const project = [
    {
      title: "GIFT CITY",
      image: "pic2.jpg",
    },
    {
      title: "PRE-LEASED BANKS",
      image: "pic2.jpg",
    },
    {
      title: "PRE-LEASED F&B",
      image: "pic3.jpg",
    },
    {
      title: "PRE-LEASED RETAIL",
      image: "pic4.jpg",
    },
    {
      title: "DHOLERA SIR NA LAND PARCELS",
      image: "pic5.jpg",
    }
  ];

  return (
    <div className="page-wrapper">
      <div
        className="dez-bnr-inr overlay-black-middle"
        style={{ backgroundImage: "url(images/background/bg4.jpg)" }}
      >
        <div className="container px-5">
          <div className="dez-bnr-inr-entry text-start">
            <h1 className="text-white">Projects</h1>
          </div>
        </div>
      </div>
      <div className="breadcrumb-row">
        <div className="container px-5">
          <ul className="list-inline text-start">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Projects</li>
          </ul>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center bg-light h-100 w-100">
        <div className="container-fluid my-5">
          <div
            className="clearfix clearfixProjects"
            style={{ height: "100% !important" }}
          >
            <ul className="h-100 row d-flex justify-content-start align-items-center">
              {
                project?.map((value, index) => {
                  return (
                    <li
                      className="completed projectimg col-lg-3 col-md-3 col-sm-12 col-12 mb-4"
                      key={index}
                    >
                      <div className="dez-box debox">
                        <div className="dez-thum dez-img-overlay1 dez-img-effect zoom-slow">
                          <img src={`images/gallery/${value?.image}`} alt="" />
                          <div className="overlay-bx">
                            <div className="overlay-icon">
                              <h2 className="m-t0 text-white">
                                {value?.title}
                              </h2>
                              <div className="clearfix m-b15">
                                <div className="dez-separator bg-white"></div>
                              </div>
                              <a
                                href={`images/gallery/${value?.image}`}
                                className=""
                                target="_blank"
                                title="View image"
                              >
                                <i className="projecticon fa fa-image d-flex justify-content-center align-items-center icon-bx-xs radius-sm"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
