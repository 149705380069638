import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {  fileUpload, sendData } from "../api/sendDataApi";
import * as Yup from "yup";

const PartnerWithUs = () => {
  const FileRef = useRef()
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      company: "",
      authName: "",
      position: "",
      contact: "",
      detail: "",
      date: "",
      location: "",
      image: null,
    },
    validationSchema: Yup.object().shape({
      company: Yup.string().required("Company name is required"),
      authName: Yup.string().required("Authorized person name is required"),
      position: Yup.string().required("Authorized person position is required"),
      contact: Yup.string()
        .required("Contact number is required")
        .matches(/^\d{10}$/, "Contact number must be a 10-digit number"),
      detail: Yup.string().required("Company details are required"),
      date: Yup.date().required("Visit date is required"),
      location: Yup.string().required("Visit location is required"),
      image: Yup.string().required("Visiting card image is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const data = {
        company_name: values.company,
        authorized_person_name: values.authName,
        business_title: values.position,
        contact_no: `${values.contact}`,
        business_details: values.detail,
        visiting_card_image: image,
        visit_date: values.date,
        visit_location: values.location,
        site: "Construction",
      };
      await sendData(data)
        .then((resp) => {
          if (resp?.status === "Success") {
            toast.success(resp?.message);
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => toast.error(err?.message))
        .finally(form.resetForm, setLoading(false), FileRef.current.value = "");
    },
  });

  const handleChange = (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    fileUpload(formData)
      .then((res) => {
        setImage(res?.data[0]);
        form.setFieldTouched("image", true);
        form.setFieldValue("image", res?.data[0]);
        form.setFieldTouched("image", true);
      })
      .catch((error) => {
        return error;
      });
  };

  return (
    <div>
      <div id="loading-area" />
      <div className="page-wraper">
        <div className="page-content">
          <div
            className="dez-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(images/banner/bnr1.jpg)" }}
          >
            <div className="container px-5 text-start">
              <div className="dez-bnr-inr-entry">
                <h1 className="text-white">Partner with us</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-row">
            <div className="container px-5 text-start">
              <ul className="list-inline">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li> Partner with us</li>
              </ul>
            </div>
          </div>
          <div className="bg-white">
            <section className="section-full content-inner-1">
              <div className="container px-5 text-start">
                <div className="row">
                  <div className="col-lg-12 col-md-12 contact-style-1">
                    <div className="p-a30 bg-gray rounded clearfix">
                      <h2>Get in touch</h2>
                      <form onSubmit={form.handleSubmit}>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="m-b20">
                              <label>Company Name</label>
                              <div className="input-group">
                                <input
                                  name="company"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Company Name"
                                  value={form.values.company}
                                  onChange={form.handleChange}
                                />
                              </div>
                              {form.errors.company && (
                                <span className="error text-danger">
                                  {form.errors.company}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="m-b20">
                              <label>Authorized Person Name</label>
                              <div className="input-group">
                                <input
                                  name="authName"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Authorized Person Name"
                                  value={form.values.authName}
                                  onChange={form.handleChange}
                                />
                              </div>
                              {form.errors.authName && (
                                <span className="error text-danger">
                                  {form.errors.authName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="m-b20">
                              <label>Authorized Person Position</label>
                              <div className="input-group text-start dropdownReact">
                                <input
                                  name="position"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Authorized Person Position"
                                  value={form.values.position}
                                  onChange={form.handleChange}
                                />
                              </div>
                              {form.errors.position && (
                                <span className="error text-danger">
                                  {form.errors.position}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="m-b20">
                              <label>Contact Number</label>
                              <div className="input-group">
                                <input
                                  name="contact"
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Contact no."
                                  value={form.values.contact}
                                  onChange={form.handleChange}
                                />
                              </div>
                              {form.errors.contact && (
                                <span className="error text-danger">
                                  {form.errors.contact}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="m-b20">
                              <label>Company Details</label>
                              <div className="input-group">
                                <input
                                  name="detail"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Company Details"
                                  value={form.values.detail}
                                  onChange={form.handleChange}
                                />
                              </div>
                              {form.errors.detail && (
                                <span className="error text-danger">
                                  {form.errors.detail}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="m-b20">
                              <label>Date</label>
                              <div className="input-group">
                                <input
                                  name="date"
                                  type="date"
                                  className="form-control"
                                  value={form.values.date}
                                  onChange={form.handleChange}
                                />
                              </div>
                              {form.errors.date && (
                                <span className="error text-danger">
                                  {form.errors.date}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="m-b20">
                              <label>Upload Image</label>
                              <div className="input-group">
                                <input
                                  name="image"
                                  type="file"
                                  ref={FileRef}
                                  accept="image/*"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </div>
                              {form.touched.image && form.errors.image && (
                                <span className="error text-danger">
                                  {form.errors.image}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="m-b20">
                              <label>Address</label>
                              <div className="input-group">
                                <input
                                  name="location"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Address"
                                  value={form.values.location}
                                  onChange={form.handleChange}
                                />
                              </div>
                              {form.errors.location && (
                                <span className="error text-danger">
                                  {form.errors.location}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <button
                              name="submit"
                              type="submit"
                              value="Submit"
                              className="site-button"
                              disabled={loading}
                            >
                              {loading ? "Loading..." : <span>Submit</span>}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerWithUs;
