import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Home from "../components/Home";
import About from "../components/About";
import Career from "../components/Career";
import PartnerWithUs from "../components/PartnerWithUs";
import Contact from "../components/Contact";
import ComingSoon from "../components/ComingSoon";
import Projects from "../components/Projects";
import Blog2 from "../components/blogs/Blog2";
import Blog3 from "../components/blogs/Blog3";
import Blog1 from "../components/blogs/Blog1";

const MainRoutes = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/career" element={<Career />} />
        <Route path="/partnerwithus" element={<PartnerWithUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/navigating-the-real-estate-investment-landscape" element={<Blog1 />} />
        <Route path="/residential-vs-commercial-vs-land" element={<Blog2 />} />
        <Route path="/reits" element={<Blog3 />} />
        <Route path="/*" element={<ComingSoon />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default MainRoutes;
