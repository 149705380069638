import React from "react";
import { Link } from "react-router-dom";

const ComingSoon = ({setState}) => {
  return (
    <div className="page-wraper d-flex flex-column justify-content-center align-items-center" style={{height: '60vh', width: '100%'}}>
      {/* <div
        id="particles-star"
        className="dez-coming-soon bg-img-fix dez-coming-soon-full  cs-style-1 p-b100 bg-dark"
        style={{height: '100vh'}}
      >
        <div className="container relative z-index2" style={{height: '50vh'}}>
          <div className="dez-coming-bx d-flex flex-column justify-content-center align-items-center text-center f-ele-m">
            <div className="top-head text-center logo-header">
              <Link to="/" className="logo-light">
                <img src="images/logo-white.png" alt="" />
              </Link>
            </div>
            <div className="coming-soon-content text-center text-white m-b30">
              <h2 id="text">We Are Coming Soon</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <div className="text-center m-t50 info-style-5">
              <div className="m-b30">
                <ul className="dez-social-icon dez-border">
                  <li>
                    <Link
                      className="fab fa-facebook-f"
                      to="/"
                    />
                  </li>
                  <li>
                    <Link
                      className="fab fa-twitter"
                      to="/"
                    />
                  </li>
                  <li>
                    <Link
                      className="fab fa-linkedin-in"
                      to="/"
                    />
                  </li>
                  <li>
                    <Link
                      className="fab fa-instagram"
                      to="/"
                    />
                  </li>
                </ul>
              </div>
              <Link
                to="/"
                onClick={() => setState(true)}
                className="site-button m-b30 radius-xl m-lr5 openbtn"
              >
                Back
              </Link>
              <Link
                to="/contact"
                onClick={() => setState(true)}
                className="site-button m-b30 radius-xl m-lr5"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div> */}
      <p>Hold on...</p>
      <h1>We are under Construction</h1>
    </div>
  );
};

export default ComingSoon;
